import React from "react";

import Content from "./Content";
import Image from "./Image";
import data from "../../resources/data/data";

let bck, right, left, comp1, comp2;

const Products = () => {
  const Product = data.map((item, index) => {
    if (index % 2 !== 0) {
      bck = 'bck_white';
      comp2 = (
        <Content list={item.points} right= {true} left= {false} title={item.title} />
      );
      comp1 = (<Image pic={item.image} />);
    } else {
      bck = 'bck_black';
      comp2 = <Image pic={item.image} />;
        comp1 = (
        <Content list={item.points} right= {false} left= {true} title={item.title} />
      );
    };
    function createMarkup() {
      return item.content;
    };

    return (
      <div className={bck}>
        <div className="center_wrapper">
          <div className="discount_wrapper">
            {comp1}
            {comp2}
          </div>
          <div style={{ padding: "70px 0px" }}>
            <div dangerouslySetInnerHTML={createMarkup()} />
          </div>
        </div>
      </div>
    );
  });
  return <div>{Product} </div>;
};

export default Products;
