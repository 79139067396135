import React from 'react';
import Fade from 'react-reveal/Fade';
import logo from '../../resources/images/icons/logo.png';

const Footer = () => {
    return (
        <footer className="bck_black">
            <Fade delay={500} duration={1000}>
                <div ><img src={logo} height= '100px' /></div>
                <div className="footer_copyright">
                    Copyright GELVIN INTERNATIONAL 2020 .All rights reserved.
                </div>
                <div className="footer_email">contact us at: admin@gelvin.in</div>
            </Fade>
        </footer>
    );
};

export default Footer;