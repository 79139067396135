import React from "react";
import Zoom from "react-reveal/Zoom";

const Image = (props) => {
    return (
      <Zoom>
        <div className='image_s'>
          <img src={props.pic} height="250px" />
        </div>
      </Zoom>
    );
};

export default Image;
