import React from "react";
import Fade from "react-reveal/Fade";

const About = () => {
  return (
    <Fade>
      <div className="center_wrapper" style={{ padding: "70, 0"}} >
        <div className="highlight_description">
          <h1>About Us</h1>
          <div className="about">
            <p>
              We apply new technology and ideas with innovative designs and new
              thinking to create more simple, valuable and trusted experiences
              with technology, continuously improving the way our customers live
              and work. </p>
              <p> Gelvin is a technology company
              which was established in 1996. Since then is has been serving the
              market and customer with new and innovative products. Exploring
              new technology products with innovative designs and ideas with
              affordable prices in the main aim of the company. </p>
                <p> Gelvin offer
                consumers a wide range of products including entertainment and
                computing and also multipurpose products which are our signature
                products due to new designs and concepts. This versatile and wide
                portfolio helps us match the good products at right and affordable
                prices and we also provide solutions to our customers' specific
              needs. </p>
                  <p> We have developed and implemented solutions for multiple
                  market segments, across a range of technologies in India. We have
                  been in the forefront in introducing new technologies and
              solutions. </p>
                    <p>Gelvin offer consumers a wide range of products
                    including entertainment and computing and also multipurpose
                    products which are our signature products due to new designs and
                    concepts. This versatile and wide portfolio helps us match the
                    good products at right and affordable prices and we also provide
              solutions to our customers' specific needs. </p>
                      <p>Our LED TV's have already been very good success in the market. Recently we have
                      entered in Touch Interactive Display's and Digital Signage Business as well, we have high quality LED TV's
                      and and Smart TV's which meet the consumer's expectations and are
              made with Latest Japanese Technology. </p>
                        <p>We at Gelvin are committed to
                        deliver the latest technology with High Quality at affordable
                        prices. Our Latest Product LED TV's are also according to our
                        company's Vision. </p>
           
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default About;
