import React, { Component } from 'react';
import './resources/styles.css';
import { Element } from 'react-scroll';


import Header from './components/header_footer/Header';
import Featured from './components/featured';
import Highlight from './components/Highlights';
import Pricing from './components/pricing';
import Footer from './components/header_footer/Footer';
import Products from './components/Products';
import About from './components/About';



class App extends Component {
  render() {
    return (
      <div className="App" style={{ height: "1500px", background: "offWhite" }}>
        <Header />
        <Element name="slides">
          <Featured /> 
        </Element>

        <Element name="touch">
          <Products />
        </Element>

        <Element name="info">
          <About />
        </Element>

        <Element name="">
          <Footer />
        </Element>
      </div>
    );
  }
}

export default App;
