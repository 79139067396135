import touch from '../product-img/touch.png';
import signage from '../product-img/signage.png';
import tv from '../product-img/tv.png';

const data = [
  {
    id:1,
    title: "Touch Interactive Display",
    points: [
      "20 Points capacitive touch.",
      "Toughened glass durable panel.",
      "Horizontal or vertical screen orientation.",
      "Can serve as customer service kiosk.",
      "Built in BT, WiFi and high power amplifier.",
    ],
    content: {
      __html:
        " Gelvin Touch Screen is the newest member of Gelvin Product Range. Touch Interactive Screen is the need for today’s developing and fast changing world where humans need to perform multitasking with ease and style. <p/> <p>Touch Interactive Screen makes our life easier; let it be Digital Classroom for face to face student interaction or online classes where a single teacher is able to teach a large number of students located at various remote places. <p/>  <p>Touch Interactive Screens can be put to use as product information screens at a company outlet for a great customer experience and for them to gather all desired information about the product that makes the work of the sales team easy. We can also support with customized software development as an extra service for specific use cases of Touch Displays. <p/>  <p>Our Touch displays feature toughened glass touch for safety and long life. Size can be customized as per your requirements. High Quality sensitive 20 points capacitive touch interface. Built in high power amplifier and Bluetooth connectivity saves cost and need for an external  amplifier for adding extra speakers to the main unit for bigger spaces.<p/>",
    },
    image: touch,
  },

  { 
    id:2,
    title: "Digital Signage",
    points: [
      "Custom made to sizes as per requirement.",
      "Screen can be divided as per your wish.",
      "Software can be customized as per needs.",
      "Available with or without touch panel.",
      "Display contents can be remotely uploaded.",
    ],
    content: {
      __html:
        "Gelvin Digital Signage is the newest member of the Gelvin product range. For years we have been serving the industry with out world class products.<p/>  <p>Gelvin Digital Signage lets you deliver your message and makes your presence anywhere in the world by displaying your contents remotely on the signage screen.<p/>  <p>It servers variety of purposes from advertising in malls and shops, delivering educational content to students or public located at various remote locations simultaneously , to serving customers in banks or corporates as information, feedback or service kiosks. .<p/>  <p>The size of the screen can be made according to needs and our software solution is user friendly. .<p/>  <p>We can also develop for you customized software solution for your specific use case. We are keen to serve you and please you with our services and products.<p/>",
    },
    image: signage,
  },

  {
    id:3,
    title: "LED TV's",
    points: [
      "Latest DLED slim panel.",
      "High dynamic contrast ratio.",
      "Low power consumption.",
      "Picture noise reduction.",
      "A+ grade panel.",
    ],
    content: {
      __html:
        " Gelvin LED TV’s has been in the market since past few years and now it has made space for itself in the hearts of users and our channel partners for its exceptional quality and long service life. Gelvin TV’s use DLED technology and is available in various models including smart android models. <p/>  <p>It is backed by years of our experience in serving the industry with latest technology products. Our TV’s feature all the standard features as USB playback support, AV inputs, HDMI inputs. Our TV’s use the best quality materials available in the market. <p/>",
    },
    image: tv,
  },
];

export default data;
