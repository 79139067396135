import React, { Component } from "react";
import Slide from "react-reveal/Slide";

import MyButton from "../utils/MyButton";

const Content = (props) => {
  const points = props.list.map((point) => <li>{point}</li>);
  return (
   
      <Slide right={props.right} left={props.left}>
        <div className="discount_description contents">
          <h2>{props.title} </h2>
          <ul>{points}</ul>

          <MyButton
            text="View More"
            bck="#ffa800"
            color="#ffffff"
            link=""
          />
        </div>
      </Slide>
  
  );
};

export default Content;
